import { inject, Injectable } from '@angular/core';
import { AccountService } from 'src/app/account/application/account.service';
import { ECapabilities } from 'src/app/account/domain/account.model';
import { FreightShipService } from 'src/app/freight-ship/application/freight-ship.service';
import { FreightService } from 'src/app/freight/application/freight.service';
import { MessagesUiService } from 'src/app/layout/services/messages-ui.service';
import { MarketService } from 'src/app/market/application/market.service';

@Injectable({
  providedIn: 'root',
})
export class RefreshDataService {
  private readonly freightService = inject(FreightService);
  private readonly freightShipService = inject(FreightShipService);
  private readonly marketService = inject(MarketService);
  private readonly accountService = inject(AccountService);
  private readonly messagesUiService = inject(MessagesUiService);

  constructor() {}

  refreshAll(): void {
    this.freightService.refreshFreightsInprogress();
    this.freightService.refreshFreightsFinished();
    this.freightService.refreshFreightstWithPendingDocumentation();
    this.accountService.hasCapability$(ECapabilities.VIEW_MARKET).subscribe((hasCap) => {
      if (hasCap) {
        this.marketService.refreshFreightsInMarket();
      }
    });
    this.accountService.hasCapability$(ECapabilities.UPLOAD_FREIGHTS).subscribe((hasCap) => {
      if (hasCap) {
        this.freightShipService.refreshFreightsShipNotFinished();
        this.freightShipService.refreshFreightsShipFinished();
      }
    });

    // TODO refresh company documents; refresh invoices

    // TODO refresh users/drivers; refresh vehicles

    // TODO review this, only clear messages related with freights?
    this.messagesUiService.clearMessages();
  }
}
